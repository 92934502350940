import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, signOut } from '../redux/slices/user';
import { useRouter } from 'next/router';
const routesAvailableMembers = [
  '/service',
  '/inbox',
  '/user/settings',
  '/signin',
  '/groups',
  '/legal',
  '/support',
  '/legal/privacy',
  'legal/copyright',
  'legal/policies',
  '/legal/terms',
  '/dinner-party-search',
  '/welcome',
];
const UserWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { userData, userChurch } = useSelector((state) => state.user);

  const router = useRouter();

  // useEffect(() => {
  //   if (localStorage.getItem('access_token') && router.pathname !== '/account-setup')
  //     setTimeout(() => {
  //       dispatch(getUser());
  //     }, 1000);
  // }, []);

  useEffect(() => {
    if (router.pathname.indexOf('/admin/account-setup')<0) {
      const tokenNotAvailable = !localStorage.getItem('access_token') || !localStorage.getItem('refresh_token');
      const userNotExist = userData ? !userData.id : true;

      if (tokenNotAvailable && userNotExist && router.pathname.indexOf('admin') === 1) {
        router.push('/')
      }
      if (!tokenNotAvailable && userNotExist) {
        const church = localStorage.getItem('current_church')
        if (church === 'null' || church === 'undefined') {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('newChurchId');
          localStorage.removeItem('current_church');
          router.push('/');
          return;
        }
        try {
          dispatch(getUser());
        } catch (e) {
          console.log(e);
        }
      }
      if (userData.id && !userData.is_admin) {
        if (!routesAvailableMembers.includes(router.pathname)) {
          router.push('/home');
        }
      }
    }
  }, [userData]);
  return children;
};

export default UserWrapper;
