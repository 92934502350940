import '../styles/globals.css';
import { Provider, useSelector } from 'react-redux';
import { store } from '../redux/store';
import UserWrapper from '../components/user-wrapper';
import Head from 'next/head';
import './index.scss';
import '../components/landing-header/landing-header.scss';
import '../components/landing-footer/landing-footer.scss';
import '../pages/legal/policies/policies.scss';
import '../pages/legal/privacy/privacy-policy.scss';
import './legal/terms/terms-of-service.scss';
import '../pages/legal/copyright/copyright.scss';
import '../pages/support/support.scss';
import { useEffect, useRef, useState, useMemo } from 'react';
import Title from '../components/header/title';
import { useRouter } from 'next/router';

export const landingUrls = [
  '/',
  '/pricing',
  '/get-started',
  '/payment-succeed',
  '/payment-failed',
  '/legal/copyright',
  '/legal/privacy',
  '/legal/terms',
  '/legal/policies',
  '/our-story',
  '/demo',
];

const MyApp = ({ Component, pageProps }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const refDark = useRef();
  const router = useRouter();

  useEffect(() => {
    refDark.current = window.matchMedia('(prefers-color-scheme: dark)');
    onUpdate();
    window.addEventListener('change', onUpdate);
    return () => {
      window.removeEventListener('change', onUpdate);
    };
  }, []);

  function onUpdate() {
    if (refDark.current.matches) {
      setDarkTheme(true);
    }
  }

  return (
    <Provider store={store}>
      <UserWrapper>
        {landingUrls.includes(router.pathname) && (
          <>
            {/* Meta Pixel Code */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL}');
                  fbq('track', 'PageView');
                `,
              }}
            ></script>
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
                  <img height="1" width="1" style="display:none"
                  src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL}&ev=PageView&noscript=1"
                  />
                `,
              }}
            ></noscript>
            {/* End Meta Pixel Code */}
          </>
        )}
        <Head>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          {['/homepage', '/pricing-page', ...landingUrls].includes(
            router.pathname
          ) ? (
            <link
              rel="icon"
              type="image/png"
              href="/togather-favicon.png"
              id="togather-favicon-icon"
            />
          ) : (
            <>
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              {darkTheme ? (
                <link
                  rel="icon"
                  type="image/svg+xml"
                  href="/Dark-Mode-favicon.svg"
                  id="dark-scheme-icon"
                />
              ) : (
                <link
                  rel="icon"
                  type="image/svg+xml"
                  href="/Light-Mode-favicon.svg"
                  id="light-scheme-icon"
                />
              )}
            </>
          )}
          {![
            '/homepage',
            '/pricing-page',
            '/admin/reports/events',
            ...landingUrls,
          ].includes(router.pathname) && (
            <style
              id="scrollbar-admin"
              dangerouslySetInnerHTML={{
                __html: `
                  *::-webkit-scrollbar {
                    width: 0;
                  }
                `,
              }}
            />
          )}
          {['/homepage', '/pricing-page', ...landingUrls].includes(
            router.pathname
          ) && (
            <>
              <link
                rel="stylesheet"
                href="https://use.typekit.net/ccp4zee.css"
              ></link>
              <link
                href="https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,300,400&display=swap"
                rel="stylesheet"
              ></link>
            </>
          )}
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          {/* <!-- Calendly link widget begin --> */}
          {['/', '/demo'].includes(router.pathname) && (
            <>
              <link
                href="https://assets.calendly.com/assets/external/widget.css"
                rel="stylesheet"
              />
              <script
                src="https://assets.calendly.com/assets/external/widget.js"
                type="text/javascript"
                async
              ></script>
            </>
          )}
          {/* <!-- Calendly link widget end --> */}
          <meta charSet="utf-8" />

          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          {['/homepage', '/pricing-page', ...landingUrls].includes(
            router.pathname
          ) && (
            <>
              <meta
                property="og:title"
                content="Togather: Church OS for Small Groups, Member Health, Seamless Messaging, and more."
              />
              <meta
                property="og:image"
                content="https://cdn.gettogather.co/OG-img.jpg"
              />
              <meta
                property="og:image:secure_url"
                content="https://cdn.gettogather.co/OG-img.jpg"
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@gettogatherco" />
              <meta name="twitter:title" content="Togather" />
              <meta
                name="twitter:description"
                content="Church OS for Small Groups, Member Health, Seamless Messaging, and more."
              />
              <meta
                name="twitter:image"
                content="https://cdn.gettogather.co/OG-img.jpg"
              />
            </>
          )}
        </Head>
        <Component {...pageProps} />
        <Title />
      </UserWrapper>
    </Provider>
  );
};
export default MyApp;
